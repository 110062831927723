import React, { useLayoutEffect, useRef, useState } from "react";
import styles from "../Object.module.css";
import ModelCanvas from "../Draft/ModelCanvas";
import HelperService from "../../../services/HelperService";
import Model3DLib from "../../../lib/model3DLib";
import { Button } from "react-bootstrap";
import { IMimeType } from "../../../types/common";
import { IObjectModel } from "../../../types/objects";
import ModalInfoService from "../../../redux/services/ModalInfoService";

interface IProps {
  model: IObjectModel;
  modelChange: (values: { name: string; value: any }[]) => void;
}

function ModelGenerator({ model, modelChange }: IProps) {
  const modelContainer = useRef<HTMLDivElement | null>(null);
  const [canvasSize, setCanvasSize] = useState<number>(0);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const generateUSDZModel = async () => {
      let changes = [];
      if (!model.model || !model.model.url) return;

      ModalInfoService.showModalInfoLoading();

      let modelFile = await applyRotationToModel(
        model.model.url,
        model.rotation ?? 0
      );

      if (!modelFile) {
        ModalInfoService.closeModalInfo();
        return;
      };

      const iOSModelUrl = await Model3DLib.handleLoadIOSModelFile(
        modelFile!,
      );

      if (iOSModelUrl) {
        changes.push({
          name: "iosModel",
          value: {
            url: iOSModelUrl,
            file: await HelperService.dataUrlToFile(
              iOSModelUrl,
              "model.usdz",
              IMimeType.iOSModel
            ),
          },
        });
      }

      ModalInfoService.closeModalInfo();
      modelChange(changes);
  };

  const applyRotationToModel = async (
      url: string,
      rotation: number
    ): Promise<File | undefined> => {
      const newFileUrl = await Model3DLib.applyRotationToFileUrl(url, rotation);
      return await HelperService.dataUrlToFile(
        newFileUrl ? newFileUrl : url,
        "model.glb",
        IMimeType.model
      );
    };

  useLayoutEffect(() => {
    if (modelContainer.current) {
      setCanvasSize(
        Math.min(
          modelContainer.current.offsetWidth - 100,
          modelContainer.current.offsetHeight - 150
        )
      );
    }
  }, []);

  return (
    <div
      data-test-id="mdl-container"
      ref={modelContainer}
      className={`w-100 h-100 position-relative d-flex align-items-center justify-content-center ${styles.modelContainer}`}
    >
      {model.model && model.model.url ? (
        <>
          <div
            style={{ width: canvasSize, height: canvasSize }}
            className={styles.modelViewerOuterBorder}
          >
            <ModelCanvas
              model={model.model.url}
              width={canvasSize}
              exposure={0.8}
              rotation={model.rotation || 0}
              verticalRotation={0}
              canvasRef={canvasRef}
              zoom={1} preset={undefined}
            />
          </div>
          <Button variant="primary" size="sm" onClick={generateUSDZModel}>
            Generate USDZ model
          </Button>
        </>
      ) : (
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <p>No model to process</p>
        </div>
      )}
    </div>
  );
}

export default ModelGenerator;
