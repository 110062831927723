import axios from "axios";
import CommonService from "../CommonService";
import HelperService from "../HelperService";
import constants from "../../constants";
import { IAttachmentType } from "../../types/common";
import { IObjectModel } from "../../types/objects";

class ObjectModelService {
  saveIOSModel = async (
    id: string,
    modelData: IObjectModel
  ) => {
    try {
      const getUploadUrl = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/getFileUploadUrl`;
      const versionId = id;

      if (modelData.iosModel) {
        let iOSModelFile = modelData.iosModel.file;

        if (iOSModelFile) {
          await CommonService.uploadFile(getUploadUrl, iOSModelFile, {
            targetId: versionId,
            attachmentType: IAttachmentType.IOSModel,
            mimeType: iOSModelFile.type,
          });
        }
      }

      await HelperService.delay(3000);

      return true;
    } catch {
      return false;
    }
  };

  deleteModelFile = async (fileName: string, targetId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/DeleteFile`;

    return axios.post(url, { fileName, targetId }).then();
  };
}
export default new ObjectModelService();
