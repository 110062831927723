import React from 'react';
import { useUpdateCheck } from 'react-update-notification';
import { Button, Modal } from "react-bootstrap";

function UpdateNotification() {
    const [modalShow, setModalShow] = React.useState(true);

    const { status, reloadPage } = useUpdateCheck({
        type: 'interval',
        interval: 60000,
    });

    if (status === 'checking' || status === 'current') {
        return null;
    }

  return (
    <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Update available
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>New version available. Please refresh the page to update the app.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={reloadPage}>Refresh now</Button>
        </Modal.Footer>
    </Modal>
  );
};

export default UpdateNotification;
