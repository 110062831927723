import { IComponentCellProps, IDisplayObject, ITableColumn } from "../../../types/common";
import styles from "../../../styles/common.module.css";
import React from "react";

function Columns(): ITableColumn[] {
  return [
    {
      id: "name",
      displayName: "Company",
      width: "240px",
    },
    {
      id: "activeDealsCount",
      displayName: "Active deals",
      width: "140px",
    },
    {
      id: "scheduledDealsCount",
      displayName: "Scheduled deals",
      width: "140px",
    },
    {
      id: "couponsCount",
      displayName: "Coupons",
      width: "140px",
    },
    {
      id: "country",
      displayName: "Country",
      pattern: (v: IDisplayObject) => (v ? v.displayName : ""),
    },
    {
      id: "isArchived",
      displayName: "Status",
      width: "100px",
      component: StatusCell,
    },
    {
      id: "isDefault",
      displayName: "Subscribe all users",
      width: "150px",
      component: BooleanPropCell,
    },
    {
      id: "defaultCountries",
      displayName: "Subscribe users from these countries",
      component: MultipleValuesPropCell,
    },
  ];
}

function StatusCell(props: IComponentCellProps) {
  return (
    <div
      className={styles.tag}
      style={{
        backgroundColor: props.value ? "#E9ECEF" : "#28A745",
        color: props.value ? "#212529" : "#FFFFFF",
      }}
    >
      {props.value ? "Archived" : "Published"}
    </div>
  );
}

function BooleanPropCell(props: IComponentCellProps) {
  return (
    <div
      className={styles.tag}
      style={{
        backgroundColor: props.value ?  "#28A745" : "#E9ECEF",
        color: props.value ? "#FFFFFF" : "#212529",
      }}
    >
      {props.value ? "True" : "False"}
    </div>
  );
}

function MultipleValuesPropCell(props: IComponentCellProps): JSX.Element {
  return <>
    {
      props.value && props.value.length
        ? props.value.map((country: IDisplayObject) => (
        <div
          key={country.id}
          className={styles.tag}
          style={{
            backgroundColor: "#E9ECEF",
            color: "#212529",
          }}
        >
          {country.displayName}
        </div>
        ))
        : <></>
    }
  </>
}

export default Columns;
