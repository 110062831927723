import { IDisplayObject, IFileWithUrl } from "./common";

export enum IObjectState {
  Published = "Published",
  Active = "Active",
  Archived = "Archived",
  New = "New",
  Draft = "Draft",
}

export enum ObjectPageState {
  info,
  versionEditor,
  draftEditor,
  adminEditor,
  conditionsEditor,
  newObject,
  modelGenerator,
}

export interface IObject {
  id: string;
  internalName: string;
  iconUrl: string;
  country: IDisplayObject;
  region: IDisplayObject;
  place: IDisplayObject;
  tags: string[];
  status: IObjectState;
  draft: IObjectState | null;
}

export interface IObjectMap {
  id: string;
  internalName: string;
  location: {
    lat: number;
    lon: number;
  };
}

export interface ITrophyExportRecord {
  ownerId: string;
  ownerEmail: string;
  objectId: string;
  objectName: string;
  timestamp: number | string;
}

export enum CollectionDistance {
  
  ExtraClose = "ExtraClose",
  Close = "Close",
  Default = "Default",
  AboveMedium = "AboveMedium",
  Far = "Far",
  ExtraFar = "ExtraFar"
}
export interface ICollectionConditions {
  maxCollections?: number | null;
  question?: string | null;
  answer?: string | null;
  requiredObjectId?: string | null;
}
export interface IObjectAdmin {
  id: string;
  internalName: string;
  country: IDisplayObject | null;
  region: IDisplayObject | null;
  place: IDisplayObject | null;
  tags: string[];
  notes: string;
  adminPictures: IFileWithUrl[];
  sharingUrl?: string;
  scheduleId: string | null;
  sendAnnouncements: boolean;
  collectionDistance: CollectionDistance;
  collectionConditions: ICollectionConditions;
}

export const initObjectAdmin: IObjectAdmin = {
  id: "",
  internalName: "",
  country: null,
  region: null,
  place: null,
  tags: [],
  notes: "",
  adminPictures: [],
  scheduleId: null,
  sendAnnouncements: true,
  collectionDistance: CollectionDistance.Default,
  collectionConditions: {},
};

export interface IObjectVersion {
  id: string;
  objectId?: string;
  versionName: string;
  displayName: string;
  imageFile: string;
  iconFile: string;
  modelFile: string;
  location: {
    lat: number;
    lon: number;
  };
  details: string;
  link: {
    url: string;
    title: string;
  };
  extraPhotos: string[];
  assetsBaseUrl: string;
  state: IObjectState;
  modelAuthor: { id: string; displayName: string };
  modelAuthorNote: string;
}

export interface IObjectVersionForm {
  id: string;
  objectId: string;
  title: string;
  coordinates: string;
  details: string;
  extraPhotos: IFileWithUrl[];
  linkTitle: string;
  link: string;
  modelAuthor: string;
  modelAuthorNote: string;
}

export const initObjectVersionForm: IObjectVersionForm = {
  id: "",
  objectId: "",
  coordinates: "",
  title: "",
  details: "",
  extraPhotos: [],
  linkTitle: "",
  link: "",
  modelAuthor: "",
  modelAuthorNote: "",
};

export interface IObjectModel {
  model?: IFileWithUrl;
  iosModel?: IFileWithUrl;
  image?: IFileWithUrl;
  icon?: IFileWithUrl;
  rotation?: number;
}



export const getCollectionDistanceLabel = (distance: CollectionDistance) => {
  switch (distance) {

    case CollectionDistance.ExtraClose:
      return "Close range (50m)";
    case CollectionDistance.Close:
      return "Below normal (75m)";
    case CollectionDistance.Default:
      return "Normal (100m)";
    case CollectionDistance.AboveMedium:
      return "Above normal (200m)";
    case CollectionDistance.Far:
      return "Distant (300m)";
    case CollectionDistance.ExtraFar:
      return "Most distant (500m)";
    default:
      return distance;
  }
}

export const envPresets = [
  undefined,
  "sunset",
  "dawn",
  "night",
  "warehouse",
  "forest",
  "apartment",
  "studio",
  "city",
  "park",
  "lobby",
] as const;
export type envPresetType = typeof envPresets[number];
