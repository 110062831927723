import React, { useEffect, useState } from 'react';
import { Token, Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { IDisplayObject } from '../../types/common';
import GeonamesService from "../../services/GeonamesService";

interface IProp {
    name: string;
    value: IDisplayObject[];
    disabled: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CountriesLookup: React.FC<IProp> = ({ name, value = [], disabled = false, onChange }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [allCountries, setAllCountries] = useState<IDisplayObject[]>([]);

    const [selectedCountries, setSelectedCountries] = useState<IDisplayObject[]>([]);

    const loadAllCountries = () => {
        GeonamesService.getAllCountries().then((res) => {
            if (res) {
                const countries: IDisplayObject[] = res.map(s => {
                    return {
                        id: s.id,
                        displayName: s.name
                    }
                });

                setAllCountries(countries);
                setSelectedCountries(value);
            } else {
                setAllCountries([]);
            }

            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadAllCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = (selected: Option[]) => {
        const event = {
            target: {
                name: name,
                value: selected,
            },
        };

        setSelectedCountries(selected as IDisplayObject[]);

        // Small hack to make it compatible with existing code. It's good to refactor it in the future.
        onChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
    }

    return (
      <Typeahead
        id={name}
        multiple
        disabled={disabled}
        isLoading={isLoading}
        labelKey="displayName"
        onChange={handleOnChange}
        options={allCountries}
        style={{ width: "100%" }}
        placeholder="Choose countries..."
        renderInput={(inputProps, props) => (
          <TypeaheadInputMulti {...inputProps} selected={selectedCountries}>
            {selectedCountries.map((option) => (
              <Token
                key={option.id}
                onRemove={(option) => { if (!disabled) props.onRemove(option);}}
                option={option}>
                {option.displayName}
              </Token>
            ))}
          </TypeaheadInputMulti>
        )}
        selected={selectedCountries}
      />
    );
}

export default CountriesLookup;