import { IDisplayObject, ILink } from "./common";
import moment from "moment/moment";

export interface IAdvertiserContext {
  locationsCount: number;
  displayTimeZone: string | null;
}
export interface IAdvertiserItemShort {
  id: string;
  name: string;
}
export interface IAdvertiserItem {
  id: string;
  name: string;
  description: string;
  websiteUrl: string | null;
  country: IDisplayObject | null;
  region: IDisplayObject | null;
  place: IDisplayObject | null;
  locationsCount?: number;
  isArchived: boolean;
  displayTimeZone: string | null;
  defaultCountries: IDisplayObject[];
  isDefault: boolean;

  iconUrl: string | null;
  extraImageUrl1: string | null;
  extraImageUrl2: string | null;
  extraImageUrl3: string | null;
}

export const initAdItem: IAdvertiserItem = {
  id: "",
  name: "",
  description: "",
  websiteUrl: null,
  country: null,
  region: null,
  place: null,
  locationsCount: 0,
  isArchived: false,
  displayTimeZone: null,
  iconUrl: null,
  extraImageUrl1: null,
  extraImageUrl2: null,
  extraImageUrl3: null,
  defaultCountries: [],
  isDefault: false
};

export interface IAdvertiserListItem extends IAdvertiserItem {
  activeDealsCount: number;
  scheduledDealsCount: number;
}

export enum IDealStatus {
  Draft = "Draft",
  Active = "Active",
  Scheduled = "Scheduled",
  Expired = "Expired",
}

export interface IDeal {
  id: string;
  name: string;
  imageUrl: string;
  locations: ILocation[];
  status: IDealStatus;
  advertiserId: string;
  startDisplayOn: number;
  endDisplayOn: number;
  link?: ILink | null;
  description: string;
}

export interface IDealForm {
  id: string;
  name: string;
  imageUrl: string;
  advertiserId: string;
  startDisplayOn: string;
  endDisplayOn: string;
  linkTitle: string;
  link: string;
  status: IDealStatus;
  locations: ILocation[];
  description: string;
}

export const initDealForm: IDealForm = {
  advertiserId: "",
  endDisplayOn: moment(new Date()).add(1, "w").format("YYYY-MM-DD"),
  id: "",
  imageUrl: "",
  link: "",
  linkTitle: "",
  name: "",
  startDisplayOn: moment(new Date()).format("YYYY-MM-DD"),
  status: IDealStatus.Draft,
  locations: [],
  description: "",
};

export interface ILocation {
  id: string;
  coordinates: string;
  address: string;
  name: string;
  advertiserId: string;
  adminNotes: string;
  collectorNotes: string;
  dealsCount?: number;
  link: string;
}

export const initLocation: ILocation = {
  address: "",
  adminNotes: "",
  advertiserId: "",
  id: "",
  link: "",
  coordinates: "",
  name: "",
  collectorNotes: "",
};


export interface ISponsorship {
  id: string;
  mapObject: IDisplayObject | null;
  collection: IDisplayObject | null;
  sponsorshipType: string;
  startsOn: number;
  expiresOn: number;
}

export interface ISponsorshipDisplayItem {
  id: string;
  itemTitle: string;
  itemType: string;
  sponsorshipType: string;
  startsOn: string;
  expiresOn: string;
}

export enum ISponsorshipType {
  Sponsor = "Sponsor",
  Owner = "Owner",
}

export interface ISponsorshipForm {
  advertiserId: string;
  mapObjectId: string;
  startsOn: number;
  expiresOn: number;
  sponsorshipType: ISponsorshipType;
}

export const initSponsorshipForm: ISponsorshipForm = {
  advertiserId: "",
  mapObjectId: "",
  startsOn: moment().add(1, "d").valueOf(),
  expiresOn: moment().add(1, "M").add(1, "d").valueOf(),
  sponsorshipType: ISponsorshipType.Sponsor,
};


export interface IAdUserDisplayItem {
  id: string;
  email: string;
  name: string;
  role: string;
}

export interface IAdUserForm {
  languageCode: string;
  advertiserId: string;
  id: string;
  email: string;
  name: string;
}

export const initAdUserForm = (advertiserId:string): IAdUserForm => ({
  advertiserId,
  id: "",
  email: "",
  name: "",
  languageCode: "en",
});