import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Object.module.css";
import ObjectPublic from "./ObjectPublic/ObjectPublic";
import ObjectAdmin, { ActiveTab } from "./ObjectAdmin/ObjectAdmin";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Draft from "./Draft/Draft";
import AdminEditor from "./AdminEditor/AdminEditor";
import { ObjectPageState } from "../../types/objects";
import GoBack from "../common/GoBack";
import { showModal } from "../../redux/action-creators/modal";
import { useDispatch } from "react-redux";
import { clearObjStore } from "../../redux/action-creators/object";
import ConditionsEditor from "./AdminEditor/ConditionsEditor";
import Model from "./Model/Model";

interface IProps {
  state?: ObjectPageState;
}

function Object({ state }: IProps) {
  const [pageState, setPageState] = useState<ObjectPageState>(
    state || ObjectPageState.info
  );
  const [activeTabState, setActiveTabState] = useState<ActiveTab>(ActiveTab.Admin);
  const [editorVersionId, setEditorVersionId] = useState<string | null>(null);
  const navigate = useNavigate();
  const search = useTypedSelector((state) => state.navbar.searchString);
  const objWasChanged = useTypedSelector((state) => state.object.wasChanged);
  const dispatch = useDispatch();

  const onLeavePage = (action: Function) => {
    if (objWasChanged) {
      dispatch(
        showModal({
          title: "Unsaved changes",
          text: "There are unsaved changes in collections list.\nPlease discard or save them before leaving the page.",
          primaryAction: () => {},
          primaryText: "Ok",
          oneActionMode: true,
        })
      );
    } else {
      action();
      dispatch(clearObjStore());
    }
  };
  const onGoBackClick = () => {
    onLeavePage(handleGoBack);
  };

  const handleGoBack = () => {
    navigate(search.length ? "/objects?search=" + search : "/objects");
  };

  const handleDuplicateClick = (versionId: string) => {
    onLeavePage(() => {
      setEditorVersionId(versionId);
      setPageState(ObjectPageState.versionEditor);
    });
  };

  const handleGenerateModelClick = (versionId: string) => {
    onLeavePage(() => {
      setEditorVersionId(versionId);
      setPageState(ObjectPageState.modelGenerator);
    });
  };

  const handleEditPublicClick = (draftId: string) => {
    onLeavePage(() => {
      setEditorVersionId(draftId);
      setPageState(ObjectPageState.draftEditor);
    });
  };

  const handleEditAdminClick = () => {
    setPageState(ObjectPageState.adminEditor);
  };

  const handleEditConditionsClick = () => {
    setPageState(ObjectPageState.conditionsEditor);
  };

  const handleInfoClick = (activeTab?: ActiveTab) => {
    setActiveTabState(activeTab ?? ActiveTab.Admin);
    setPageState(ObjectPageState.info);
  };

  return (
    <>
      {pageState === ObjectPageState.info && (
        <>
          <GoBack handleGoBack={onGoBackClick} text={"Back to Objects list"} />
          <div className={`d-flex align-items-stretch ${styles.container}`}>
            <ObjectPublic
              duplicateClick={handleDuplicateClick}
              editClick={handleEditPublicClick}
              backToList={handleGoBack}
              generateModelClick={handleGenerateModelClick}
            />
            <ObjectAdmin editAdminInfoClick={handleEditAdminClick} editConditionsClick={handleEditConditionsClick} activeTab={activeTabState} />
          </div>
        </>
      )}
      {pageState === ObjectPageState.versionEditor && (
        <Draft versionId={editorVersionId} backToInfo={handleInfoClick} />
      )}
      {pageState === ObjectPageState.modelGenerator && (
        <Model versionId={editorVersionId} backToInfo={handleInfoClick} />
      )}
      {pageState === ObjectPageState.draftEditor && (
        <Draft
          versionId={editorVersionId}
          backToInfo={handleInfoClick}
          isNewVersion={false}
        />
      )}
      {pageState === ObjectPageState.adminEditor && (
        <AdminEditor backToInfo={handleInfoClick} />
      )}
      {pageState === ObjectPageState.conditionsEditor && (
        <ConditionsEditor backToInfo={() => handleInfoClick(ActiveTab.Conditions)} />
      )}
      {pageState === ObjectPageState.newObject && (
        <Draft backToInfo={handleInfoClick} backToList={handleGoBack} />
      )}
    </>
  );
}

export default Object;
